// src/pages/StockDatas.js
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Box,
  Checkbox,
  Button,
  Toolbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import HomePage from "./HomePage";
import axios from "axios";

// Styled components for enhanced styling
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  margin: "auto",
  marginTop: theme.spacing(2),
  boxShadow: theme.shadows[5],
  borderRadius: theme.shape.borderRadius,
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
}));

const StyledTableCellHeader = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: "bold",
  fontSize: "1rem",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:hover": {
    backgroundColor: theme.palette.action.selected,
    cursor: "pointer",
  },
}));


const ErrorContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: theme.spacing(3),
}));

const StockDatas = () => {
  const [symbols, setSymbols] = useState([]);
  const [records, setRecords] = useState([]);
  const [error, setError] = useState(false);
  const [selected, setSelected] = useState([]); // State for selected rows
  const [open, setOpen] = useState(false); // State for confirmation dialog
  const [loading, setLoading] = useState(false); // State for loading
  const [message, setMessage] = useState(""); // Feedback message
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state

  // Replace with your backend API URL
  const API_URL = "https://api.arasu.online/api/marketcap/freefloat-issued-sizes";
  const FETCH_ALL_URL = "https://api.arasu.online/api/stock/fetch-all";

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    // Fetch initial data if needed
    // fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await fetch(API_URL);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setSymbols(data.symbols);
      setRecords(data.records);
      setMessage("Data fetched successfully.");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(true);
      setMessage("Error loading data. Please try again later.");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  // Handler for selecting all rows
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = records.map((n) => n.date);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // Handler for selecting a single row
  const handleClick = (event, date) => {
    const selectedIndex = selected.indexOf(date);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, date);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // Handler for opening the confirmation dialog
  const handleOpen = () => {
    setOpen(true);
  };

  // Handler for closing the confirmation dialog
  const handleClose = () => {
    setOpen(false);
  };

  // Handler for confirming deletion
  const handleConfirmDelete = () => {
    const newRecords = records.filter(
      (record) => !selected.includes(record.date)
    );
    setRecords(newRecords);
    setSelected([]);
    setOpen(false);
    setMessage("Selected records deleted successfully.");
    setSnackbarOpen(true);
  };

  const isSelected = (date) => selected.indexOf(date) !== -1;

  const handleFetchAll = async () => {
    setLoading(true);
    setMessage("Fetching data...");
    setSnackbarOpen(true);
    try {
      const response = await axios.post(FETCH_ALL_URL);
      setMessage(response.data.Message || "Data fetched successfully.");
    } catch (error) {
      setMessage("Error fetching data.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Handler for closing the Snackbar
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  if (error) {
    return (
      <>
        <HomePage />
        <ErrorContainer>
          <Typography variant="h6" color="error" align="center">
            Error loading data. Please try again later.
          </Typography>
        </ErrorContainer>
      </>
    );
  }

  return (
    <>
      <HomePage />
      <Box sx={{ padding: theme.spacing(2) }}>
        {/* Button Container using Box */}
        <Box
          sx={{
            display: "flex",
            gap: "20px", // 20px gap between buttons
            mb: 2,
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleFetchAll}
            disabled={loading}
            sx={{
              minWidth: "150px",
              transition: "background-color 0.3s",
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Mac Data Download"
            )}
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            onClick={fetchData}
            sx={{
              minWidth: "150px",
              transition: "background-color 0.3s",
              "&:hover": {
                backgroundColor: theme.palette.secondary.dark,
              },
            }}
          >
              Stock Data View
          </Button>
        </Box>

        {/* Toolbar with Delete Button */}
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            gap:2,
           
          }}
        >
          {selected.length > 0 && (
            <>
              <Button
                variant="contained"
                color="error"
                onClick={handleOpen}
                sx={{
                  transition: "background-color 0.3s",
                  "&:hover": {
                    backgroundColor: theme.palette.error.dark,
                  },
                }}
              >
                Delete
              </Button>
              {/* Confirmation Dialog */}
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
              >
                <DialogTitle id="delete-dialog-title">
                  {"Confirm Deletion"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="delete-dialog-description">
                    Are you sure you want to delete the selected records?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button
                    onClick={handleConfirmDelete}
                    color="error"
                    autoFocus
                  >
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
          {selected.length > 0 ? (
            <Typography
              sx={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {selected.length} selected
            </Typography>
          ) : (
            <Typography
              sx={{ flex: "1 1 100%" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Free Float Issued Sizes by Market Cap
            </Typography>
          )}

          
        </Toolbar>

     
        {/* Table Container */}
        { records.length > 0 && (
          <StyledTableContainer component={Paper}>
            <Table
              aria-label="market cap table"
              size={isMobile ? "small" : "medium"}
              
            >
              <StyledTableHead>
                <TableRow>
                  <StyledTableCellHeader padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={
                        selected.length > 0 && selected.length < records.length
                      }
                      checked={
                        records.length > 0 && selected.length === records.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{
                        "aria-label": "select all records",
                      }}
                    />
                  </StyledTableCellHeader>
                  <StyledTableCellHeader>
                    <Typography>Date</Typography>
                  </StyledTableCellHeader>
                  {symbols.map((symbol) => (
                    <StyledTableCellHeader key={symbol}>
                      <Typography>{symbol}</Typography>
                    </StyledTableCellHeader>
                  ))}
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {records.map((record) => {
                  const isItemSelected = isSelected(record.date);
                  const labelId = `enhanced-table-checkbox-${record.date}`;

                  return (
                    <StyledTableRow
                      hover
                      onClick={(event) => handleClick(event, record.date)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={record.date}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Typography>
                          {new Date(record.date).toLocaleDateString()}
                        </Typography>
                      </TableCell>
                      {symbols.map((symbol) => (
                        <TableCell key={symbol}>
                          <Typography>
                            {record.freefloatIssuedSizes[symbol]
                              ? record.freefloatIssuedSizes[symbol].toLocaleString()
                              : "N/A"}
                          </Typography>
                        </TableCell>
                      ))}
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </StyledTableContainer>
        )}

        {/* No Data Message */}
        {!loading && records.length === 0 && (
          <Box sx={{ textAlign: "center", mt: 5 }}>
            <Typography variant="h6">No data available.</Typography>
          </Box>
        )}

        {/* Snackbar for Feedback Messages */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={error ? "error" : "success"}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default StockDatas;
