// src/pages/GeneralSettings.js
import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Snackbar,
  Alert,
  CircularProgress, // <-- Added import
} from "@mui/material";
import { styled } from "@mui/material/styles";
import HomePage from "./HomePage";

// Importing icons for better visual representation
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import axios from "axios";

// Styled component for consistent card styling
const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 500,
  margin: "auto",
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius,
}));

// Styled CardHeader with smaller typography for different card types
const SmallCardHeader = styled(CardHeader)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText,
  "& .MuiCardHeader-title": {
    fontSize: "1rem", // Smaller font size
    fontWeight: 500,  // Optional: Adjust font weight
  },
}));

const SmallDeleteAllCardHeader = styled(CardHeader)(({ theme }) => ({
  backgroundColor: theme.palette.error.light,
  color: theme.palette.error.contrastText,
  "& .MuiCardHeader-title": {
    fontSize: "1rem", // Smaller font size
    fontWeight: 500,  // Optional: Adjust font weight
  },
}));

const SmallSuccessCardHeader = styled(CardHeader)(({ theme }) => ({
  backgroundColor: theme.palette.success.light,
  color: theme.palette.success.contrastText,
  "& .MuiCardHeader-title": {
    fontSize: "1rem", // Smaller font size
    fontWeight: 500,  // Optional: Adjust font weight
  },
}));

const GeneralSettings = () => {
  // State for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  // Loading states
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingDeleteAll, setLoadingDeleteAll] = useState(false);
  const [loadingRefresh, setLoadingRefresh] = useState(false);

  // Function to extract error messages
  const getErrorMessage = (error) => {
    if (error.response) {
      if (typeof error.response.data === "string") {
        return error.response.data;
      } else if (error.response.data && error.response.data.message) {
        return error.response.data.message;
      }
    }
    return "An unexpected error occurred.";
  };

  // Handler to close the Snackbar
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  // Action Handlers
  const handleDownloadSymbol = async () => {
    setLoadingDownload(true);
    try {
      const response = await axios.post('https://api.arasu.online/api/Symbol/all-symbol-download');
      console.log(response.data.message);
      setSnackbarMessage("Symbols downloaded successfully.");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      const message = getErrorMessage(error);
      setSnackbarMessage(message);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoadingDownload(false);
    }
  };

  const handleDeleteSymbol = async () => {
    setLoadingDelete(true);
    try {
      const response = await axios.delete('https://api.arasu.online/api/Symbol/all-symbol-delete');
      console.log(response.data.message);
      setSnackbarMessage("Symbol deleted successfully.");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      const message = getErrorMessage(error);
      setSnackbarMessage(message);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoadingDelete(false);
    }
  };

  const handleDeleteAllViewDatas = async () => {
    setLoadingDeleteAll(true);
    try {
      // Replace with your delete all view data logic (e.g., API call)
      // Example:
      const response = await axios.delete('https://api.arasu.online/api/Data/DeleteAll');
      // Handle response...

      // Simulating success response
      setSnackbarMessage("All view data deleted successfully.");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      const message = getErrorMessage(error);
      setSnackbarMessage(message);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoadingDeleteAll(false);
    }
  };

  const handleRefreshToken = async () => {
    setLoadingRefresh(true);
    try {
      const userId = localStorage.getItem('userId'); // Replace with dynamic value

      const response = await axios.post('https://api.arasu.online/api/Auth/refresh-token', { userId });

      const newAccessToken = response.data.access_token;

      console.log(newAccessToken);
  
      setSnackbarMessage("Token refreshed successfully.");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      const message = getErrorMessage(error);
      setSnackbarMessage(message);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoadingRefresh(false);
    }
  };

  return (
    <>
      <HomePage />

      {/* Container for all settings cards */}
      <Box sx={{ padding: 2 }}>
        <Stack spacing={2}>
          {/* Symbol Data Service Card */}
          <StyledCard>
            <SmallCardHeader title="Symbol Data Service" />
            <CardContent>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12}>
                  <Typography variant="body1" align="center">
                    Manage your symbol data by downloading or deleting symbols.
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<DownloadIcon />}
                    onClick={handleDownloadSymbol}
                    disabled={loadingDownload || loadingDelete}
                    sx={{
                      minWidth: 150,
                      transition: "background-color 0.3s",
                      "&:hover": {
                        backgroundColor: (theme) =>
                          theme.palette.primary.dark,
                      },
                    }}
                  >
                    {loadingDownload ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Download Symbol"
                    )}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="medium"
                    startIcon={<DeleteIcon />}
                    onClick={handleDeleteSymbol}
                    disabled={loadingDownload || loadingDelete}
                    sx={{
                      minWidth: 150,
                      transition: "background-color 0.3s",
                      "&:hover": {
                        backgroundColor: (theme) =>
                          theme.palette.secondary.dark,
                      },
                    }}
                  >
                    {loadingDelete ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Delete Symbol"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>

          {/* Delete All View Data Card */}
          <StyledCard>
            <SmallDeleteAllCardHeader title="Delete All View Data" />
            <CardContent>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12}>
                  <Typography variant="body1" align="center">
                    Manage your view data by deleting all view records.
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="error"
                    size="medium"
                    startIcon={<DeleteIcon />}
                    onClick={handleDeleteAllViewDatas}
                    disabled={loadingDeleteAll}
                    sx={{
                      minWidth: 200,
                      transition: "background-color 0.3s",
                      "&:hover": {
                        backgroundColor: (theme) =>
                          theme.palette.error.light,
                      },
                    }}
                  >
                    {loadingDeleteAll ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Delete All View Datas"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>

          {/* Refresh Token Card */}
          <StyledCard>
            <SmallSuccessCardHeader title="Refresh Token" />
            <CardContent>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12}>
                  <Typography variant="body1" align="center">
                    Refresh your authentication tokens to maintain secure access.
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="success"
                    size="medium"
                    startIcon={<RefreshIcon />}
                    onClick={handleRefreshToken}
                    disabled={loadingRefresh}
                    sx={{
                      minWidth: 180,
                      transition: "background-color 0.3s",
                      "&:hover": {
                        backgroundColor: (theme) =>
                          theme.palette.success.dark,
                      },
                    }}
                  >
                    {loadingRefresh ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Refresh Token"
                    )}
                  </Button>
                </Grid>
                {/* Add more buttons or actions as needed */}
              </Grid>
            </CardContent>
          </StyledCard>
        </Stack>
      </Box>

      {/* Snackbar for Feedback Messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default GeneralSettings;
