import React from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  TextField,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const UserDataEditModel = ({ open, handleClose, formData, handleChange }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
      
            <Typography variant="h6" component="h2">
             Edit User Details
            </Typography>
            <TextField
              fullWidth
              label="Username"
              name="username"
              variant="outlined"
              margin="normal"
              value={formData.username}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label="Client Id"
              name="client_id"
              variant="outlined"
              margin="normal"
              value={formData.client_id}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label="Secretkey"
              name="secretkey"
              variant="outlined"
              margin="normal"
              multiline
              value={formData.secretkey}
              onChange={handleChange}
            />
             <TextField
              fullWidth
              label="Redirect Uri"
              name="redirect_uri"
              variant="outlined"
              margin="normal"
              multiline
              value={formData.redirect_uri}
              onChange={handleChange}
            />
             <TextField
              fullWidth
              label="Refresh Token"
              name="refresh_token"
              variant="outlined"
              margin="normal"
              multiline
              value={formData.refresh_token}
              onChange={handleChange}
            />
             <TextField
              fullWidth
              label="Access Token"
              name="access_token"
              variant="outlined"
              margin="normal"
              multiline
              value={formData.access_token}
              onChange={handleChange}
            />
            
            <Button fullWidth  sx={{ mt: 2, backgroundColor:"#84cf0e" }} variant="contained">
              Submit
            </Button>
            <Button fullWidth onClick={handleClose} sx={{ mt: 2, backgroundColor:"#f0a918" }} variant="contained">
              Close
            </Button>
         
      </Box>
    </Modal>
  );
};

export default UserDataEditModel;
