import React from 'react';
import { Typography, Box } from '@mui/material';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state to display fallback UI
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service here
    this.setState({ errorInfo });
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box sx={{ padding: 4, textAlign: 'center' }}>
          <Typography variant="h4" color="error" gutterBottom>
            Something went wrong.
          </Typography>
          <Typography variant="body1">
            Please try refreshing the page or contact support if the problem persists.
          </Typography>
          {process.env.NODE_ENV === 'development' && this.state.errorInfo && (
            <Box sx={{ marginTop: 2, textAlign: 'left' }}>
              <Typography variant="h6">Error Details:</Typography>
              <Typography variant="body2">{this.state.error && this.state.error.toString()}</Typography>
              <Typography variant="body2">{this.state.errorInfo.componentStack}</Typography>
            </Box>
          )}
        </Box>
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
