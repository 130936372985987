// strategyCalculations.js

// Bullish Strategies
export const calculateBuyCall = (buyCall) => {
    const premium = parseFloat(buyCall.Ltp);
    const strikePrice = parseFloat(buyCall.Strike_price);
  
    return {
      strategy: "Buy Call",
      strikePrice,
      premium,
      maxLoss: premium,
      maxProfit: "Unlimited",
      breakeven: strikePrice + premium,
    };
  };
  
  export const calculateSellPut = (sellPut) => {
    const premium = parseFloat(sellPut.Ltp);
    const strikePrice = parseFloat(sellPut.Strike_price);
  
    return {
      strategy: "Sell Put",
      strikePrice,
      premium,
      maxLoss: strikePrice - premium, // Potentially substantial if price falls
      maxProfit: premium,
      breakeven: strikePrice - premium,
    };
  };
  
  export const calculateBullCallSpread = (buyCall, sellCall) => {
    const buyPremium = parseFloat(buyCall.Ltp);
    const sellPremium = parseFloat(sellCall.Ltp);
    const buyStrike = parseFloat(buyCall.Strike_price);
    const sellStrike = parseFloat(sellCall.Strike_price);
  
    const netPremium = buyPremium - sellPremium;
    const maxProfit = sellStrike - buyStrike - netPremium;
    const maxLoss = netPremium;
    const breakeven = buyStrike + netPremium;
  
    return {
      strategy: "Bull Call Spread",
      buyStrikePrice: buyStrike,
      sellStrikePrice: sellStrike,
      netPremium,
      maxProfit,
      maxLoss,
      breakeven,
    };
  };
  
  export const calculateBullPutSpread = (sellPut, buyPut) => {
    const sellPremium = parseFloat(sellPut.Ltp);
    const buyPremium = parseFloat(buyPut.Ltp);
    const sellStrike = parseFloat(sellPut.Strike_price);
    const buyStrike = parseFloat(buyPut.Strike_price);
  
    const netPremium = sellPremium - buyPremium;
    const maxProfit = netPremium;
    const maxLoss = sellStrike - buyStrike - netPremium;
    const breakeven = sellStrike - netPremium;
  
    return {
      strategy: "Bull Put Spread",
      sellStrikePrice: sellStrike,
      buyStrikePrice: buyStrike,
      netPremium,
      maxProfit,
      maxLoss,
      breakeven,
    };
  };
  
  export const calculateCallRatioBackSpread = (sellCall, buyCall1, buyCall2) => {
    const sellPremium = parseFloat(sellCall.Ltp);
    const buyPremium1 = parseFloat(buyCall1.Ltp);
    const buyPremium2 = parseFloat(buyCall2.Ltp);
    const sellStrike = parseFloat(sellCall.Strike_price);
    const buyStrike1 = parseFloat(buyCall1.Strike_price);
    const buyStrike2 = parseFloat(buyCall2.Strike_price);
  
    const netPremium = buyPremium1 + buyPremium2 - sellPremium;
    const maxProfit = "Unlimited";
    const maxLoss = netPremium;
    const breakeven = sellStrike + netPremium;
  
    return {
      strategy: "Call Ratio Back Spread",
      sellStrikePrice: sellStrike,
      buyStrikePrices: [buyStrike1, buyStrike2],
      netPremium,
      maxProfit,
      maxLoss,
      breakeven,
    };
  };
  
  export const calculateLongCalendarWithCalls = (longCall, shortCall) => {
    const longPremium = parseFloat(longCall.Ltp);
    const shortPremium = parseFloat(shortCall.Ltp);
    const longStrike = parseFloat(longCall.Strike_price);
    const shortStrike = parseFloat(shortCall.Strike_price);
  
    const netPremium = longPremium - shortPremium;
    const maxLoss = netPremium;
    const breakeven = longStrike + netPremium;
  
    return {
      strategy: "Long Calendar with Calls",
      longStrikePrice: longStrike,
      shortStrikePrice: shortStrike,
      netPremium,
      maxProfit: "Unlimited",
      maxLoss,
      breakeven,
    };
  };
  
  export const calculateBullCondor = (buyPut, sellPut, sellCall, buyCall) => {
    const buyPutPremium = parseFloat(buyPut.Ltp);
    const sellPutPremium = parseFloat(sellPut.Ltp);
    const sellCallPremium = parseFloat(sellCall.Ltp);
    const buyCallPremium = parseFloat(buyCall.Ltp);
  
    const netPremium = sellPutPremium + sellCallPremium - buyPutPremium - buyCallPremium;
    const maxProfit = netPremium;
    const maxLoss = Math.min(
      sellPutPremium - buyPutPremium,
      buyCallPremium - sellCallPremium
    );
    const breakevenLower = parseFloat(buyPut.Strike_price) + netPremium;
    const breakevenUpper = parseFloat(sellCall.Strike_price) - netPremium;
  
    return {
      strategy: "Bull Condor",
      buyPutStrikePrice: parseFloat(buyPut.Strike_price),
      sellPutStrikePrice: parseFloat(sellPut.Strike_price),
      sellCallStrikePrice: parseFloat(sellCall.Strike_price),
      buyCallStrikePrice: parseFloat(buyCall.Strike_price),
      netPremium,
      maxProfit,
      maxLoss,
      breakevenLower,
      breakevenUpper,
    };
  };
  
  export const calculateBullButterfly = (buyCall, sellCall1, sellCall2, buyCall3) => {
    const buyPremium1 = parseFloat(buyCall.Ltp);
    const sellPremium1 = parseFloat(sellCall1.Ltp);
    const sellPremium2 = parseFloat(sellCall2.Ltp);
    const buyPremium2 = parseFloat(buyCall3.Ltp);
    const buyStrike = parseFloat(buyCall.Strike_price);
    const sellStrike1 = parseFloat(sellCall1.Strike_price);
    const sellStrike2 = parseFloat(sellCall2.Strike_price);
    const buyStrike2 = parseFloat(buyCall3.Strike_price);
  
    const netPremium = buyPremium1 + buyPremium2 - sellPremium1 - sellPremium2;
    const maxProfit = sellStrike1 - buyStrike - netPremium;
    const maxLoss = netPremium;
    const breakevenLower = buyStrike + netPremium;
    const breakevenUpper = sellStrike1 - netPremium;
  
    return {
      strategy: "Bull Butterfly",
      buyStrikePrice1: buyStrike,
      sellStrikePrice1: sellStrike1,
      sellStrikePrice2: sellStrike2,
      buyStrikePrice2: buyStrike2,
      netPremium,
      maxProfit,
      maxLoss,
      breakevenLower,
      breakevenUpper,
    };
  };
  
  export const calculateZeroCost = (buyOption, sellOption) => {
    const buyPremium = parseFloat(buyOption.Ltp);
    const sellPremium = parseFloat(sellOption.Ltp);
    const buyStrike = parseFloat(buyOption.Strike_price);
    const sellStrike = parseFloat(sellOption.Strike_price);
  
    const netPremium = buyPremium - sellPremium;
    const maxProfit = "Unlimited";
    const maxLoss = netPremium;
    const breakeven = buyStrike + netPremium;
  
    return {
      strategy: "Zero Cost",
      buyStrikePrice: buyStrike,
      sellStrikePrice: sellStrike,
      netPremium,
      maxProfit,
      maxLoss,
      breakeven,
    };
  };
  
  export const calculateRangeForward = (buyOption, sellOption) => {
    const buyPremium = parseFloat(buyOption.Ltp);
    const sellPremium = parseFloat(sellOption.Ltp);
    const buyStrike = parseFloat(buyOption.Strike_price);
    const sellStrike = parseFloat(sellOption.Strike_price);
  
    const netPremium = buyPremium - sellPremium;
    const maxProfit = sellStrike - buyStrike - netPremium;
    const maxLoss = netPremium;
    const breakeven = buyStrike + netPremium;
  
    return {
      strategy: "Range Forward",
      buyStrikePrice: buyStrike,
      sellStrikePrice: sellStrike,
      netPremium,
      maxProfit,
      maxLoss,
      breakeven,
    };
  };
  
  export const calculateLongSyntheticFuture = (buyCall, sellPut) => {
    const buyPremium = parseFloat(buyCall.Ltp);
    const sellPremium = parseFloat(sellPut.Ltp);
    const strikePrice = parseFloat(buyCall.Strike_price);
  
    const netCost = buyPremium - sellPremium;
    const maxLoss = netCost;
    const maxProfit = "Unlimited";
    const breakeven = strikePrice + netCost;
  
    return {
      strategy: "Long Synthetic Future",
      strikePrice,
      netCost,
      maxProfit,
      maxLoss,
      breakeven,
    };
  };
  
  // Bearish Strategies
  export const calculateBuyPut = (buyPut) => {
    const premium = parseFloat(buyPut.Ltp);
    const strikePrice = parseFloat(buyPut.Strike_price);
  
    return {
      strategy: "Buy Put",
      strikePrice,
      premium,
      maxLoss: premium,
      maxProfit: parseFloat(buyPut.Strike_price), // Maximum profit if asset drops to zero
      breakeven: strikePrice - premium,
    };
  };
  
  export const calculateSellCall = (sellCall) => {
    const premium = parseFloat(sellCall.Ltp);
    const strikePrice = parseFloat(sellCall.Strike_price);
  
    return {
      strategy: "Sell Call",
      strikePrice,
      premium,
      maxLoss: "Unlimited", // If asset price rises indefinitely
      maxProfit: premium,
      breakeven: strikePrice + premium,
    };
  };
  
  export const calculateBearCallSpread = (sellCall, buyCall) => {
    const sellPremium = parseFloat(sellCall.Ltp);
    const buyPremium = parseFloat(buyCall.Ltp);
    const sellStrike = parseFloat(sellCall.Strike_price);
    const buyStrike = parseFloat(buyCall.Strike_price);
  
    const netPremium = sellPremium - buyPremium;
    const maxProfit = netPremium;
    const maxLoss = buyStrike - sellStrike - netPremium;
    const breakeven = sellStrike + netPremium;
  
    return {
      strategy: "Bear Call Spread",
      sellStrikePrice: sellStrike,
      buyStrikePrice: buyStrike,
      netPremium,
      maxProfit,
      maxLoss,
      breakeven,
    };
  };
  
  export const calculateBearPutSpread = (buyPut, sellPut) => {
    const buyPremium = parseFloat(buyPut.Ltp);
    const sellPremium = parseFloat(sellPut.Ltp);
    const buyStrike = parseFloat(buyPut.Strike_price);
    const sellStrike = parseFloat(sellPut.Strike_price);
  
    const netPremium = buyPremium - sellPremium;
    const maxProfit = sellStrike - buyStrike - netPremium;
    const maxLoss = netPremium;
    const breakeven = buyStrike - netPremium;
  
    return {
      strategy: "Bear Put Spread",
      buyStrikePrice: buyStrike,
      sellStrikePrice: sellStrike,
      netPremium,
      maxProfit,
      maxLoss,
      breakeven,
    };
  };
  
  export const calculatePutRatioBackSpread = (sellPut, buyPut1, buyPut2) => {
    const sellPremium = parseFloat(sellPut.Ltp);
    const buyPremium1 = parseFloat(buyPut1.Ltp);
    const buyPremium2 = parseFloat(buyPut2.Ltp);
    const sellStrike = parseFloat(sellPut.Strike_price);
    const buyStrike1 = parseFloat(buyPut1.Strike_price);
    const buyStrike2 = parseFloat(buyPut2.Strike_price);
  
    const netPremium = buyPremium1 + buyPremium2 - sellPremium;
    const maxProfit = "Substantial";
    const maxLoss = netPremium;
    const breakeven = sellStrike - netPremium;
  
    return {
      strategy: "Put Ratio Back Spread",
      sellStrikePrice: sellStrike,
      buyStrikePrices: [buyStrike1, buyStrike2],
      netPremium,
      maxProfit,
      maxLoss,
      breakeven,
    };
  };
  
  export const calculateLongCalendarWithPuts = (longPut, shortPut) => {
    const longPremium = parseFloat(longPut.Ltp);
    const shortPremium = parseFloat(shortPut.Ltp);
    const longStrike = parseFloat(longPut.Strike_price);
    const shortStrike = parseFloat(shortPut.Strike_price);
  
    const netPremium = longPremium - shortPremium;
    const maxLoss = netPremium;
    const breakeven = longStrike - netPremium;
  
    return {
      strategy: "Long Calendar with Puts",
      longStrikePrice: longStrike,
      shortStrikePrice: shortStrike,
      netPremium,
      maxProfit: "Substantial",
      maxLoss,
      breakeven,
    };
  };
  
  export const calculateBearCondor = (buyPut, sellPut, sellCall, buyCall) => {
    const buyPutPremium = parseFloat(buyPut.Ltp);
    const sellPutPremium = parseFloat(sellPut.Ltp);
    const sellCallPremium = parseFloat(sellCall.Ltp);
    const buyCallPremium = parseFloat(buyCall.Ltp);
  
    const netPremium = sellPutPremium + sellCallPremium - buyPutPremium - buyCallPremium;
    const maxProfit = netPremium;
    const maxLoss = Math.min(
      sellPutPremium - buyPutPremium,
      buyCallPremium - sellCallPremium
    );
    const breakevenLower = parseFloat(buyPut.Strike_price) + netPremium;
    const breakevenUpper = parseFloat(sellCall.Strike_price) - netPremium;
  
    return {
      strategy: "Bear Condor",
      buyPutStrikePrice: parseFloat(buyPut.Strike_price),
      sellPutStrikePrice: parseFloat(sellPut.Strike_price),
      sellCallStrikePrice: parseFloat(sellCall.Strike_price),
      buyCallStrikePrice: parseFloat(buyCall.Strike_price),
      netPremium,
      maxProfit,
      maxLoss,
      breakevenLower,
      breakevenUpper,
    };
  };
  
  export const calculateBearButterfly = (buyPut, sellPut1, sellPut2, buyPut3) => {
    const buyPremium1 = parseFloat(buyPut.Ltp);
    const sellPremium1 = parseFloat(sellPut1.Ltp);
    const sellPremium2 = parseFloat(sellPut2.Ltp);
    const buyPremium2 = parseFloat(buyPut3.Ltp);
    const buyStrike = parseFloat(buyPut.Strike_price);
    const sellStrike1 = parseFloat(sellPut1.Strike_price);
    const sellStrike2 = parseFloat(sellPut2.Strike_price);
    const buyStrike2 = parseFloat(buyPut3.Strike_price);
  
    const netPremium = buyPremium1 + buyPremium2 - sellPremium1 - sellPremium2;
    const maxProfit = sellStrike1 - buyStrike - netPremium;
    const maxLoss = netPremium;
    const breakevenLower = buyStrike - netPremium;
    const breakevenUpper = sellStrike1 + netPremium;
  
    return {
      strategy: "Bear Butterfly",
      buyStrikePrice1: buyStrike,
      sellStrikePrice1: sellStrike1,
      sellStrikePrice2: sellStrike2,
      buyStrikePrice2: buyStrike2,
      netPremium,
      maxProfit,
      maxLoss,
      breakevenLower,
      breakevenUpper,
    };
  };
  
  export const calculateRiskReversal = (buyCall, sellPut) => {
    const callPremium = parseFloat(buyCall.Ltp);
    const putPremium = parseFloat(sellPut.Ltp);
    const callStrike = parseFloat(buyCall.Strike_price);
    const putStrike = parseFloat(sellPut.Strike_price);
  
    const netCost = callPremium - putPremium;
    const maxProfit = "Unlimited";
    const maxLoss = putStrike - netCost;
    const breakeven = putStrike + netCost;
  
    return {
      strategy: "Risk Reversal",
      buyCallStrikePrice: callStrike,
      sellPutStrikePrice: putStrike,
      netCost,
      maxProfit,
      maxLoss,
      breakeven,
    };
  };
  
  export const calculateShortSyntheticFuture = (buyPut, sellCall) => {
    const buyPutPremium = parseFloat(buyPut.Ltp);
    const sellCallPremium = parseFloat(sellCall.Ltp);
    const putStrike = parseFloat(buyPut.Strike_price);
    const callStrike = parseFloat(sellCall.Strike_price);
  
    const netPremium = sellCallPremium - buyPutPremium;
    const maxProfit = netPremium;
    const maxLoss = "Unlimited"; // If asset price rises
    const breakeven = callStrike - netPremium;
  
    return {
      strategy: "Short Synthetic Future",
      buyPutStrikePrice: putStrike,
      sellCallStrikePrice: callStrike,
      netPremium,
      maxProfit,
      maxLoss,
      breakeven,
    };
  };
  
  // Neutral Strategies
  export const calculateShortStraddle = (sellCall, sellPut) => {
    const callPremium = parseFloat(sellCall.Ltp);
    const putPremium = parseFloat(sellPut.Ltp);
    const strikePrice = parseFloat(sellCall.Strike_price); // Assuming both have the same strike
  
    const totalPremium = callPremium + putPremium;
    const maxProfit = totalPremium;
    const maxLoss = "Unlimited"; // If asset price moves significantly
    const breakevenUp = strikePrice + totalPremium;
    const breakevenDown = strikePrice - totalPremium;
  
    return {
      strategy: "Short Straddle",
      strikePrice,
      totalPremium,
      maxProfit,
      maxLoss,
      breakevenUp,
      breakevenDown,
    };
  };
  
  export const calculateIronButterfly = (sellCall, sellPut, buyCall, buyPut) => {
    const sellCallPremium = parseFloat(sellCall.Ltp);
    const sellPutPremium = parseFloat(sellPut.Ltp);
    const buyCallPremium = parseFloat(buyCall.Ltp);
    const buyPutPremium = parseFloat(buyPut.Ltp);
    const strikePrice = parseFloat(sellCall.Strike_price); // Middle strike
  
    const netPremium = sellCallPremium + sellPutPremium - buyCallPremium - buyPutPremium;
    const maxProfit = netPremium;
    const maxLoss = Math.min(
      buyPutPremium - sellPutPremium,
      buyCallPremium - sellCallPremium
    );
    const breakevenLower = strikePrice - netPremium;
    const breakevenUpper = strikePrice + netPremium;
  
    return {
      strategy: "Iron Butterfly",
      strikePrice,
      netPremium,
      maxProfit,
      maxLoss,
      breakevenLower,
      breakevenUpper,
    };
  };
  
  export const calculateShortStrangle = (sellCall, sellPut) => {
    const callPremium = parseFloat(sellCall.Ltp);
    const putPremium = parseFloat(sellPut.Ltp);
    const sellCallStrike = parseFloat(sellCall.Strike_price);
    const sellPutStrike = parseFloat(sellPut.Strike_price);
  
    const totalPremium = callPremium + putPremium;
    const maxProfit = totalPremium;
    const maxLoss = "Unlimited"; // If asset price moves beyond strikes
    const breakevenUp = sellCallStrike + totalPremium;
    const breakevenDown = sellPutStrike - totalPremium;
  
    return {
      strategy: "Short Strangle",
      sellCallStrikePrice: sellCallStrike,
      sellPutStrikePrice: sellPutStrike,
      totalPremium,
      maxProfit,
      maxLoss,
      breakevenUp,
      breakevenDown,
    };
  };
  
  export const calculateShortIronCondor = (sellCallSpread, sellPutSpread) => {
    const sellCallPremium = parseFloat(sellCallSpread.sell.Ltp);
    const buyCallPremium = parseFloat(sellCallSpread.buy.Ltp);
    const sellPutPremium = parseFloat(sellPutSpread.sell.Ltp);
    const buyPutPremium = parseFloat(sellPutSpread.buy.Ltp);
  
    const netPremium =
      sellCallPremium + sellPutPremium - buyCallPremium - buyPutPremium;
    const maxProfit = netPremium;
    const maxLoss =
      Math.min(
        sellCallSpread.buy.Strike_price - sellCallSpread.sell.Strike_price,
        sellPutSpread.sell.Strike_price - sellPutSpread.buy.Strike_price
      ) - netPremium;
    const breakevenLower =
      parseFloat(sellPutSpread.sell.Strike_price) - netPremium;
    const breakevenUpper =
      parseFloat(sellCallSpread.sell.Strike_price) + netPremium;
  
    return {
      strategy: "Short Iron Condor",
      sellCallStrikePrice: sellCallSpread.sell.Strike_price,
      buyCallStrikePrice: sellCallSpread.buy.Strike_price,
      sellPutStrikePrice: sellPutSpread.sell.Strike_price,
      buyPutStrikePrice: sellPutSpread.buy.Strike_price,
      netPremium,
      maxProfit,
      maxLoss,
      breakevenLower,
      breakevenUpper,
    };
  };
  
  export const calculateBatman = (legs) => {
    // Assuming 'Batman' involves multiple legs: buy call, sell two calls, buy call, buy put, sell two puts, buy put
    // Adjust based on actual definition
  
    const buyCall = legs.find(
      (leg) => leg.Option_type === "CE" && leg.Strike_price < legs[0].Strike_price
    );
    const sellCall1 = legs.find(
      (leg) => leg.Option_type === "CE" && leg.Strike_price === legs[0].Strike_price
    );
    const sellCall2 = legs.find(
      (leg) => leg.Option_type === "CE" && leg.Strike_price > legs[0].Strike_price
    );
    const buyPut = legs.find(
      (leg) => leg.Option_type === "PE" && leg.Strike_price < legs[0].Strike_price
    );
    const sellPut1 = legs.find(
      (leg) => leg.Option_type === "PE" && leg.Strike_price === legs[0].Strike_price
    );
    const sellPut2 = legs.find(
      (leg) => leg.Option_type === "PE" && leg.Strike_price > legs[0].Strike_price
    );
    const buyPut2 = legs.find(
      (leg) => leg.Option_type === "PE" && leg.Strike_price > legs[0].Strike_price
    );
  
    // Simplified example; adjust based on actual leg structure
    if (!buyCall || !sellCall1 || !sellCall2 || !buyPut || !sellPut1 || !sellPut2 || !buyPut2) {
      return null;
    }
  
    // Calculate net premiums
    const buyCallPremium = parseFloat(buyCall.Ltp);
    const sellCallPremium1 = parseFloat(sellCall1.Ltp);
    const sellCallPremium2 = parseFloat(sellCall2.Ltp);
    const buyPutPremium = parseFloat(buyPut.Ltp);
    const sellPutPremium1 = parseFloat(sellPut1.Ltp);
    const sellPutPremium2 = parseFloat(sellPut2.Ltp);
    const buyPutPremium2 = parseFloat(buyPut2.Ltp);
  
    const netPremium =
      buyCallPremium + buyPutPremium2 - sellCallPremium1 - sellCallPremium2 - sellPutPremium1 - sellPutPremium2;
  
    // Define max profit, max loss based on strategy specifics
    const maxProfit = "Defined based on price movement";
    const maxLoss = "Defined based on price movement";
  
    return {
      strategy: "Batman",
      netPremium,
      maxProfit,
      maxLoss,
    };
  };
  
  export const calculateDoublePlateau = (legs) => {
    // Implement based on specific definition
    // Placeholder implementation
    return {
      strategy: "Double Plateau",
      // Add relevant calculated fields
    };
  };
  
  export const calculateJadeLizard = (sellCall, sellPut, buyPut) => {
    const sellCallPremium = parseFloat(sellCall.Ltp);
    const sellPutPremium = parseFloat(sellPut.Ltp);
    const buyPutPremium = parseFloat(buyPut.Ltp);
    const sellCallStrike = parseFloat(sellCall.Strike_price);
    const sellPutStrike = parseFloat(sellPut.Strike_price);
    const buyPutStrike = parseFloat(buyPut.Strike_price);
  
    const netPremium = sellCallPremium + sellPutPremium - buyPutPremium;
    const maxProfit = netPremium;
    const maxLoss = sellPutStrike - buyPutStrike - netPremium;
  
    return {
      strategy: "Jade Lizard",
      sellCallStrikePrice: sellCallStrike,
      sellPutStrikePrice: sellPutStrike,
      buyPutStrikePrice: buyPutStrike,
      netPremium,
      maxProfit,
      maxLoss,
    };
  };
  
  export const calculateReverseJadeLizard = (sellPut, sellCall, buyCall) => {
    const sellPutPremium = parseFloat(sellPut.Ltp);
    const sellCallPremium = parseFloat(sellCall.Ltp);
    const buyCallPremium = parseFloat(buyCall.Ltp);
    const sellPutStrike = parseFloat(sellPut.Strike_price);
    const sellCallStrike = parseFloat(sellCall.Strike_price);
    const buyCallStrike = parseFloat(buyCall.Strike_price);
  
    const netPremium = sellPutPremium + sellCallPremium - buyCallPremium;
    const maxProfit = netPremium;
    const maxLoss = buyCallStrike - sellCallStrike - netPremium;
  
    return {
      strategy: "Reverse Jade Lizard",
      sellPutStrikePrice: sellPutStrike,
      sellCallStrikePrice: sellCallStrike,
      buyCallStrikePrice: buyCallStrike,
      netPremium,
      maxProfit,
      maxLoss,
    };
  };
  