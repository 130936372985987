// src/pages/ViewData.js

import React, { useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  TextField,
  Autocomplete,
  ListItem,
  Grid
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import axios from "axios";
import HomePage from "./HomePage";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import { debounce } from "lodash";
import VirtualizedList from "../components/VirtualizedList";

const ViewData = () => {
  const theme = useTheme();

  // State to hold symbol options
  const [symbolOptions, setSymbolOptions] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [loadingSymbols, setLoadingSymbols] = useState(false);
  const [searchError, setSearchError] = useState(null);

  // State for date pickers
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  // State to hold table data and headers
  const [tableData, setTableData] = useState([]);
  const [headers, setHeaders] = useState([]);

  // State for sorting
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  // Loading and error states
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [statusMsg, setStatusMsg] = useState("");

  // State for hidden headers
  const [hiddenHeaders, setHiddenHeaders] = useState([]);

  // Debounce the API call to prevent excessive requests
  const fetchSymbolOptions = useMemo(
    () =>
      debounce(async (query) => {
        if (!query || query.trim().length < 1) {
          setSymbolOptions([]);
          return;
        }
        setLoadingSymbols(true);
        setSearchError(null);
        try {
          const token = localStorage.getItem("token");
          const response = await axios.get(
            "https://api.arasu.online/api/Data/GetSymbols",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: { query: query },
            }
          );
        
          if (response.data && Array.isArray(response.data)) {
            setSymbolOptions(response.data);
          } else {
            setSymbolOptions([]);
          }
        } catch (err) {
          console.error("Error fetching symbol options:", err);
          setSearchError("Failed to fetch symbols.");
        } finally {
          setLoadingSymbols(false);
        }
      }, 300),
    []
  );

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    fetchSymbolOptions(newInputValue);
  };

  // Handle symbol selection
  const handleSymbolChange = (event, newValue) => {
    setSelectedSymbol(newValue);
  };

  // Function to handle data retrieval
  const handleSubmit = async () => {
    if (!selectedSymbol || !fromDate || !toDate) {
      setError("Please select a symbol and date range.");
      return;
    }
    setIsLoading(true);
    setError(null);
    setStatusMsg("");

    try {
      const token = localStorage.getItem("token");
      const payload = {
        symbol: selectedSymbol,
        fromDate: fromDate,
        toDate: toDate,
      };



      const response = await axios.post(
        "https://api.arasu.online/api/Data/GetDailyData",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

   
      if (Array.isArray(response.data)) {
        setTableData(response.data);

        // Set dynamic headers based on the first entry
        if (response.data.length > 0) {
          const dynamicHeaders = Object.keys(response.data[0]).map((key) => ({
            label: formatHeaderLabel(key),
            value: key,
          }));
          setHeaders(dynamicHeaders);

          // By default, no headers are hidden when new headers are set
          setHiddenHeaders([]);
        } else {
          setHeaders([]);
          setHiddenHeaders([]);
        }
      } else {
        setTableData([]);
        setHeaders([]);
        setHiddenHeaders([]);
        setError("Invalid data format received from server.");
      }

      setStatusMsg("Data retrieved successfully.");
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to retrieve data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Helper function to format header labels
  const formatHeaderLabel = (key) => {
    // Map key to label if needed
    return key.replace(/([A-Z])/g, " $1").trim();
  };

  // Function to handle sorting
  const handleSort = (column) => {
    const isAsc = orderBy === column && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(column);

    const sortedData = [...tableData].sort((a, b) => {
      const aValue = a[column];
      const bValue = b[column];

      // Handle undefined or null values
      if (aValue === undefined || aValue === null) return 1;
      if (bValue === undefined || bValue === null) return -1;

      // Numeric sorting
      if (typeof aValue === "number" && typeof bValue === "number") {
        return isAsc ? aValue - bValue : bValue - aValue;
      }

      // Date sorting
      if (isValidDate(aValue) && isValidDate(bValue)) {
        return isAsc
          ? new Date(aValue) - new Date(bValue)
          : new Date(bValue) - new Date(aValue);
      }

      // String sorting
      return isAsc
        ? aValue.toString().localeCompare(bValue.toString())
        : bValue.toString().localeCompare(aValue.toString());
    });

    setTableData(sortedData);
  };

  // Helper function to check if a string is a valid date
  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date);
  };

  // Function to handle header selection change
  const handleHeaderChange = (event) => {
    const value = event.target.value;

    if (value.includes("select_all")) {
      if (hiddenHeaders.length === headers.length) {
        setHiddenHeaders([]); // Unhide all if all are hidden
      } else {
        setHiddenHeaders(headers.map((header) => header.value)); // Hide all if not all are hidden
      }
    } else {
      setHiddenHeaders(value);
    }
  };

  // Function to handle action button click
  const handleAction = (row) => {
    const { DateTime } = row;

    // Extract the date part from the datetime string
    const date = DateTime.split(" ")[0];

    const url = `${window.location.origin}/fifteen-min-data?symbol=${encodeURIComponent(
      selectedSymbol
    )}&date=${encodeURIComponent(date)}`;

    // Open the URL in a new tab
    window.open(url, "_blank");
  };

  return (
    <>
      <HomePage />

      {/* Input Area */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 13,
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "530px",
            p: 4,
            borderRadius: "16px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
            backgroundColor: "white",
          }}
        >


<Grid container spacing={2}>
  <Grid item xs={6}>
    

 {/* Symbol Selection */}
 <Autocomplete
            options={symbolOptions}
            getOptionLabel={(option) => option}
            value={selectedSymbol}
            onChange={handleSymbolChange}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            loading={loadingSymbols}
            ListboxComponent={VirtualizedList}
            renderOption={(props, option) => (
              <ListItem {...props} key={option}>
                {option}
              </ListItem>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Symbol"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingSymbols ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            noOptionsText={
              inputValue.trim().length < 1
                ? "Type at least 1 character"
                : "No symbols found"
            }
          />

{searchError && (
            <Typography
              variant="body2"
              color="error"
              sx={{ marginTop: theme.spacing(2), textAlign: "center" }}
            >
              {searchError}
            </Typography>
          )}

  </Grid>
  <Grid item xs={6}>
   

 {/* Controls: Header Selector */}
 {tableData.length > 0 && (
          <ControlsBox>
            {/* Header Selector */}
            <HeaderSelectorBox>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="header-select-label">Hide Columns</InputLabel>
                <Select
                  labelId="header-select-label"
                  id="header-select"
                  multiple
                  value={hiddenHeaders}
                  onChange={handleHeaderChange}
                  label="Hide Columns"
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>None</em>;
                    }

                    if (selected.length === headers.length) {
                      return "All Columns Hidden";
                    }

                    return `${selected.length} columns hidden`;
                  }}
                >
                  {/* Select All Option */}
                  <MenuItem key="select_all" value="select_all">
                    <Checkbox
                      checked={
                        hiddenHeaders.length === headers.length && headers.length > 0
                      }
                      indeterminate={
                        hiddenHeaders.length > 0 &&
                        hiddenHeaders.length < headers.length
                      }
                    />
                    <ListItemText primary="Select All" />
                  </MenuItem>

                  {/* Individual Options */}
                  {headers.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      <Checkbox checked={hiddenHeaders.includes(item.value)} />
                      <ListItemText primary={item.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </HeaderSelectorBox>
          </ControlsBox>
        )}


  </Grid>
  <Grid item xs={6}>
  <TextField
            label="Select From Date"
            type="date"
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 4 }}
            value={fromDate || ""}
            onChange={(e) => setFromDate(e.target.value)}
          />
  </Grid>
  <Grid item xs={6}>
  <TextField
            label="Select To Date"
            type="date"
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 4 }}
            value={toDate || ""}
            onChange={(e) => setToDate(e.target.value)}
          />
  </Grid>
</Grid>

          {/* Submit Button */}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              fontWeight: "bold",
              backgroundColor: "#1976d2",
              "&:hover": {
                backgroundColor: "#115293",
              },
            }}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Get Data"}
          </Button>
          {/* Status or Error Messages */}
          {statusMsg && <p style={{ color: "green" }}>{statusMsg}</p>}
          {error && <p style={{ color: "red" }}>{error}</p>}
        </Box>

       
      </Box>

      {/* Data Display Area */}
      {tableData.length > 0 && (
        <Box
          sx={{
            height: "80vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            mt: 5,
            px: 2,
          }}
        >
          {/* Data Table */}
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: "100vh",
              width: "100%",
              overflowY: "auto",
              scrollBehavior: "smooth",
              boxShadow: 3,
            }}
          >
            <Table stickyHeader aria-label="stock data table">
              <TableHead>
                <TableRow>
                  {headers
                    .filter((header) => !hiddenHeaders.includes(header.value))
                    .map((header) => (
                      <StyledTableCell
                        key={header.value}
                        onClick={() => header.value && handleSort(header.value)}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {header.label}
                          {orderBy === header.value ? (
                            order === "asc" ? (
                              <ArrowUpward fontSize="small" />
                            ) : (
                              <ArrowDownward fontSize="small" />
                            )
                          ) : null}
                        </Box>
                      </StyledTableCell>
                    ))}

                  {/* Extra header for Actions */}
                  <StyledTableCell>Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row, index) => (
                  <TableRow
                    key={index}
                    hover
                    sx={{
                      backgroundColor:
                        index % 2 === 0
                          ? theme.palette.action.hover
                          : theme.palette.background.default,
                    }}
                  >
                    {headers
                      .filter((header) => !hiddenHeaders.includes(header.value))
                      .map((header) => (
                        <TableCell
                          key={header.value}
                          sx={{
                            textAlign: "center",
                            padding: "8px",
                            borderLeft: `1px solid ${theme.palette.divider}`,
                          }}
                        >
                          {row[header.value]}
                        </TableCell>
                      ))}

                    {/* Action Button */}
                    <TableCell
                      sx={{
                        textAlign: "center",
                        padding: "8px",
                        borderLeft: `1px solid ${theme.palette.divider}`,
                      }}
                    >
                      <ActionButton
                        variant="contained"
                        onClick={() => handleAction(row)}
                      >
                        View 15Min 
                      </ActionButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
};

export default ViewData;

// Styled components for better readability and maintainability
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  textAlign: "center",
  borderLeft: `1px solid ${theme.palette.divider}`,
  cursor: "pointer",
  userSelect: "none",
  position: "sticky",
  top: 0,
  zIndex: 1,
}));

const ActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  color: theme.palette.common.white,
  padding: "6px 16px",
  "&:hover": {
    backgroundColor: theme.palette.success.dark,
    color: theme.palette.common.white,
  },
  textTransform: "none",
}));

const ControlsBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: theme.spacing(2),
  flexWrap: "wrap",
  marginBottom: theme.spacing(3),
  // marginTop: theme.spacing(3),
}));

const HeaderSelectorBox = styled(Box)(({ theme }) => ({
  minWidth: 250,
}));
