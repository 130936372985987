// strategyMapping.js

import {
    // Bullish Strategies
    calculateBuyCall,
    calculateSellPut,
    calculateBullCallSpread,
    calculateBullPutSpread,
    calculateCallRatioBackSpread,
    calculateLongCalendarWithCalls,
    calculateBullCondor,
    calculateBullButterfly,
    calculateZeroCost,
    calculateRangeForward,
    calculateLongSyntheticFuture,
  
    // Bearish Strategies
    calculateBuyPut,
    calculateSellCall,
    calculateBearCallSpread,
    calculateBearPutSpread,
    calculatePutRatioBackSpread,
    calculateLongCalendarWithPuts,
    calculateBearCondor,
    calculateBearButterfly,
    calculateRiskReversal,
    calculateShortSyntheticFuture,
  
    // Neutral Strategies
    calculateShortStraddle,
    calculateIronButterfly,
    calculateShortStrangle,
    calculateShortIronCondor,
    calculateBatman,
    calculateDoublePlateau,
    calculateJadeLizard,
    calculateReverseJadeLizard,
  } from "./strategyCalculations";
  
  // Helper function to format option key with full symbol
  const formatOptionKey = (symbol, optionType, strikePrice) => {
    return `${symbol}-${optionType}-${strikePrice}`;
  };
  
  // Strategy Mapping
  export const strategyMapping = {
    // Bullish Strategies
    "Buy Call": {
      calculate: calculateBuyCall,
      legs: (symbol, optionsData, strikePrice) => [
        optionsData[formatOptionKey(symbol, "CE", strikePrice)],
      ],
    },
    "Sell Put": {
      calculate: calculateSellPut,
      legs: (symbol, optionsData, strikePrice) => [
        optionsData[formatOptionKey(symbol, "PE", strikePrice)],
      ],
    },
    "Bull Call Spread": {
      calculate: calculateBullCallSpread,
      legs: (symbol, optionsData, strikePrice) => [
        optionsData[formatOptionKey(symbol, "CE", strikePrice)],
        optionsData[formatOptionKey(symbol, "CE", strikePrice + 50)], // ₹50 increment
      ],
    },
    "Bull Put Spread": {
      calculate: calculateBullPutSpread,
      legs: (symbol, optionsData, strikePrice) => [
        optionsData[formatOptionKey(symbol, "PE", strikePrice)],
        optionsData[formatOptionKey(symbol, "PE", strikePrice - 50)], // ₹50 decrement
      ],
    },
    "Call Ratio Back Spread": {
      calculate: calculateCallRatioBackSpread,
      legs: (symbol, optionsData, strikePrice) => [
        optionsData[formatOptionKey(symbol, "CE", strikePrice)],
        optionsData[formatOptionKey(symbol, "CE", strikePrice + 50)], // ₹50 increment
        optionsData[formatOptionKey(symbol, "CE", strikePrice + 100)], // ₹100 increment
      ],
    },
    "Long Calendar with Calls": {
      calculate: calculateLongCalendarWithCalls,
      legs: (symbol, optionsData, strikePrice) => [
        optionsData[formatOptionKey(symbol, "CE", strikePrice)],
        optionsData[formatOptionKey(symbol, "CE", strikePrice)], // Same strike
      ],
    },
    "Bull Condor": {
      calculate: calculateBullCondor,
      legs: (symbol, optionsData, strikePrice) => [
        optionsData[formatOptionKey(symbol, "PE", strikePrice - 50)],
        optionsData[formatOptionKey(symbol, "PE", strikePrice)],
        optionsData[formatOptionKey(symbol, "CE", strikePrice)],
        optionsData[formatOptionKey(symbol, "CE", strikePrice + 50)],
      ],
    },
    "Bull Butterfly": {
      calculate: calculateBullButterfly,
      legs: (symbol, optionsData, strikePrice) => [
        optionsData[formatOptionKey(symbol, "CE", strikePrice - 100)],
        optionsData[formatOptionKey(symbol, "CE", strikePrice)],
        optionsData[formatOptionKey(symbol, "CE", strikePrice)],
        optionsData[formatOptionKey(symbol, "CE", strikePrice + 100)],
      ],
    },
    "Zero Cost": {
      calculate: calculateZeroCost,
      legs: (symbol, optionsData, strikePrice) => [
        optionsData[formatOptionKey(symbol, "CE", strikePrice)],
        optionsData[formatOptionKey(symbol, "PE", strikePrice)],
      ],
    },
    "Range Forward": {
      calculate: calculateRangeForward,
      legs: (symbol, optionsData, strikePrice) => [
        optionsData[formatOptionKey(symbol, "CE", strikePrice)],
        optionsData[formatOptionKey(symbol, "PE", strikePrice)],
      ],
    },
    "Long Synthetic Future": {
      calculate: calculateLongSyntheticFuture,
      legs: (symbol, optionsData, strikePrice) => [
        optionsData[formatOptionKey(symbol, "CE", strikePrice)],
        optionsData[formatOptionKey(symbol, "PE", strikePrice)],
      ],
    },
  
    // Bearish Strategies
    "Buy Put": {
      calculate: calculateBuyPut,
      legs: (symbol, optionsData, strikePrice) => [
        optionsData[formatOptionKey(symbol, "PE", strikePrice)],
      ],
    },
    "Sell Call": {
      calculate: calculateSellCall,
      legs: (symbol, optionsData, strikePrice) => [
        optionsData[formatOptionKey(symbol, "CE", strikePrice)],
      ],
    },
    "Bear Call Spread": {
      calculate: calculateBearCallSpread,
      legs: (symbol, optionsData, strikePrice) => [
        optionsData[formatOptionKey(symbol, "CE", strikePrice)],
        optionsData[formatOptionKey(symbol, "CE", strikePrice + 50)], // ₹50 increment
      ],
    },
    "Bear Put Spread": {
      calculate: calculateBearPutSpread,
      legs: (symbol, optionsData, strikePrice) => [
        optionsData[formatOptionKey(symbol, "PE", strikePrice)],
        optionsData[formatOptionKey(symbol, "PE", strikePrice - 50)], // ₹50 decrement
      ],
    },
    "Put Ratio Back Spread": {
      calculate: calculatePutRatioBackSpread,
      legs: (symbol, optionsData, strikePrice) => [
        optionsData[formatOptionKey(symbol, "PE", strikePrice)],
        optionsData[formatOptionKey(symbol, "PE", strikePrice - 50)], // ₹50 decrement
        optionsData[formatOptionKey(symbol, "PE", strikePrice - 100)], // ₹100 decrement
      ],
    },
    "Long Calendar with Puts": {
      calculate: calculateLongCalendarWithPuts,
      legs: (symbol, optionsData, strikePrice) => [
        optionsData[formatOptionKey(symbol, "PE", strikePrice)],
        optionsData[formatOptionKey(symbol, "PE", strikePrice)], // Same strike
      ],
    },
    "Bear Condor": {
      calculate: calculateBearCondor,
      legs: (symbol, optionsData, strikePrice) => [
        optionsData[formatOptionKey(symbol, "PE", strikePrice + 50)],
        optionsData[formatOptionKey(symbol, "PE", strikePrice)],
        optionsData[formatOptionKey(symbol, "CE", strikePrice)],
        optionsData[formatOptionKey(symbol, "CE", strikePrice - 50)],
      ],
    },
    "Bear Butterfly": {
      calculate: calculateBearButterfly,
      legs: (symbol, optionsData, strikePrice) => [
        optionsData[formatOptionKey(symbol, "PE", strikePrice + 100)],
        optionsData[formatOptionKey(symbol, "PE", strikePrice)],
        optionsData[formatOptionKey(symbol, "PE", strikePrice)],
        optionsData[formatOptionKey(symbol, "PE", strikePrice - 100)],
      ],
    },
    "Risk Reversal": {
      calculate: calculateRiskReversal,
      legs: (symbol, optionsData, strikePrice) => [
        optionsData[formatOptionKey(symbol, "CE", strikePrice)],
        optionsData[formatOptionKey(symbol, "PE", strikePrice)],
      ],
    },
    "Short Synthetic Future": {
      calculate: calculateShortSyntheticFuture,
      legs: (symbol, optionsData, strikePrice) => [
        optionsData[formatOptionKey(symbol, "PE", strikePrice)],
        optionsData[formatOptionKey(symbol, "CE", strikePrice)],
      ],
    },
  
    // Neutral Strategies
    "Short Straddle": {
      calculate: calculateShortStraddle,
      legs: (symbol, optionsData, strikePrice) => [
        optionsData[formatOptionKey(symbol, "CE", strikePrice)],
        optionsData[formatOptionKey(symbol, "PE", strikePrice)],
      ],
    },
    "Iron Butterfly": {
      calculate: calculateIronButterfly,
      legs: (symbol, optionsData, strikePrice) => [
        optionsData[formatOptionKey(symbol, "CE", strikePrice)],
        optionsData[formatOptionKey(symbol, "PE", strikePrice)],
        optionsData[formatOptionKey(symbol, "CE", strikePrice + 50)],
        optionsData[formatOptionKey(symbol, "PE", strikePrice - 50)],
      ],
    },
    "Short Strangle": {
      calculate: calculateShortStrangle,
      legs: (symbol, optionsData, strikePrice) => [
        optionsData[formatOptionKey(symbol, "CE", strikePrice + 50)],
        optionsData[formatOptionKey(symbol, "PE", strikePrice - 50)],
      ],
    },
    "Short Iron Condor": {
      calculate: calculateShortIronCondor,
      legs: (symbol, optionsData, strikePrice) => [
        {
          sell: optionsData[formatOptionKey(symbol, "CE", strikePrice + 50)],
          buy: optionsData[formatOptionKey(symbol, "CE", strikePrice + 100)],
        },
        {
          sell: optionsData[formatOptionKey(symbol, "PE", strikePrice - 50)],
          buy: optionsData[formatOptionKey(symbol, "PE", strikePrice - 100)],
        },
      ],
    },
    "Batman": {
      calculate: calculateBatman,
      legs: (symbol, optionsData, strikePrice) => [
        optionsData[formatOptionKey(symbol, "CE", strikePrice - 100)],
        optionsData[formatOptionKey(symbol, "CE", strikePrice)],
        optionsData[formatOptionKey(symbol, "CE", strikePrice + 100)],
        optionsData[formatOptionKey(symbol, "PE", strikePrice - 100)],
        optionsData[formatOptionKey(symbol, "PE", strikePrice)],
        optionsData[formatOptionKey(symbol, "PE", strikePrice + 100)],
      ],
    },
    "Double Plateau": {
      calculate: calculateDoublePlateau,
      legs: (symbol, optionsData, strikePrice) => [
        // Define based on specific strategy structure
        // Placeholder: Add the necessary legs here
      ],
    },
    "Jade Lizard": {
      calculate: calculateJadeLizard,
      legs: (symbol, optionsData, strikePrice) => [
        optionsData[formatOptionKey(symbol, "CE", strikePrice + 50)], // Sell OTM Call
        optionsData[formatOptionKey(symbol, "PE", strikePrice)], // Sell OTM Put
        optionsData[formatOptionKey(symbol, "PE", strikePrice - 50)], // Buy further OTM Put
      ],
    },
    "Reverse Jade Lizard": {
      calculate: calculateReverseJadeLizard,
      legs: (symbol, optionsData, strikePrice) => [
        optionsData[formatOptionKey(symbol, "PE", strikePrice - 50)], // Sell OTM Put
        optionsData[formatOptionKey(symbol, "CE", strikePrice)], // Sell OTM Call
        optionsData[formatOptionKey(symbol, "CE", strikePrice + 50)], // Buy further OTM Call
      ],
    },
  };
  