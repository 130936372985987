/* eslint-disable react/jsx-pascal-case */
import React, { useState } from "react";
import HomePage from "./HomePage";
import { Box, Button, Grid, Tab } from "@mui/material";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import Cash_Tab from "./CashTab";
import F_and_OT_Tab from "./F_and_O_tab";
import axios from "axios";

const SubscribeSymbols = () => {
  const [value, setValue] = useState("1");

  // Example symbol options; replace with your actual data

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const subscribeSymbols = async () => {
    try {
      const response = await axios.post('https://api.arasu.online/api/Subscribe/Subscribe-symbol');
      console.log(response.data.message);
      // Optionally, update your UI to reflect successful subscription
    } catch (error) {
      console.error('Error subscribing symbols:', error.response ? error.response.data : error.message);
      // Optionally, display an error message to the user
    }
  };


  const UnsubscribeSymbols = async () => {
    try {
      const response = await axios.post('https://api.arasu.online/api/Subscribe/Un-Subscribe-symbol');
      console.log(response.data.message);
      // Optionally, update your UI to reflect successful subscription
    } catch (error) {
      console.error('Error subscribing symbols:', error.response ? error.response.data : error.message);
      // Optionally, display an error message to the user
    }
  };

  return (
    <>
      <HomePage />
      <Box sx={{ p: 2, m: 2 }}>

        
      <Grid container spacing={2} sx={{ mb: 4 }}>
      <Grid item>
        <Button
          variant="contained"
          size="medium"
          onClick={subscribeSymbols}
        >
          Subscribe to All Symbols
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          size="medium"
          onClick={UnsubscribeSymbols}
        >
          Unsubscribe to All Symbols
        </Button>
      </Grid>
    </Grid>

        <Box >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="Subscribe Symbols Tabs"
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab
                  label="Cash"
                  value="1"
                  sx={{ fontWeight: 600, fontSize: "1rem" }}
                />
                <Tab
                  label="F and O"
                  value="2"
                  sx={{ fontWeight: 600, fontSize: "1rem" }}
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Cash_Tab />
            </TabPanel>
            <TabPanel value="2">
              <F_and_OT_Tab />
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </>
  );
};

export default SubscribeSymbols;
