import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import HomePage from './HomePage';

const LogDetails = () => {
  const [logData, setLogData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSearch = () => {
    setLoading(true);
    // Simulate fetching log data from an API or server
    setTimeout(() => {
      const sampleData = [
        { id: 1, timestamp: '2024-10-25 10:30:45', message: 'User login successful' },
        { id: 2, timestamp: '2024-10-25 11:15:20', message: 'User logged out' },
        { id: 3, timestamp: '2024-10-25 12:05:10', message: 'Data updated successfully' },
      ];
      const filteredData = sampleData.filter((log) =>
        log.message.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setLogData(filteredData);
      setLoading(false);
    }, 1000);
  };

  return (
<>

<HomePage/>

    <Container maxWidth="md" style={{marginTop:"130px"}}>
      <Paper elevation={3} style={{ padding: '20px', marginTop: '30px' }}>
        <Typography variant="h4" gutterBottom>
          Log Details
        </Typography>
        <TextField
          label="Search Logs"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ marginBottom: '20px' }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<SearchIcon />}
          onClick={handleSearch}
        >
          Search
        </Button>

        {loading ? (
          <CircularProgress style={{ marginTop: '20px' }} />
        ) : (
          <TableContainer component={Paper} style={{ marginTop: '20px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Timestamp</TableCell>
                  <TableCell>Message</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logData.length > 0 ? (
                  logData.map((log) => (
                    <TableRow key={log.id}>
                      <TableCell>{log.id}</TableCell>
                      <TableCell>{log.timestamp}</TableCell>
                      <TableCell>{log.message}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      No logs found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </Container>
    </>
  );
};

export default LogDetails;
