// src/pages/FifteenMinData.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  FormControlLabel,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Grid
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import axios from 'axios';
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";

const FifteenMinData = () => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [hiddenHeaders, setHiddenHeaders] = useState([]);

  const location = useLocation();

  // Function to parse query parameters
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };

  const query = useQuery();
  const symbol = query.get('symbol');
  const date = query.get('date');

  // State for "Full 1 Min Data" checkbox
  const [fullOneMinData, setFullOneMinData] = useState(false);

  useEffect(() => {
    if (symbol && date) {
      fetchData();
    } else {
      setError('Symbol or date parameter is missing.');
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [symbol, date]);

  const fetchData = async () => {
    setIsLoading(true);
    setError('');
    try {
      const token = localStorage.getItem('token');
      const payload = {
        symbol: symbol,
        date: date,
      };
      const response = await axios.post(
        'https://api.arasu.online/api/Data/GetFifteenMinData',
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (Array.isArray(response.data)) {
        setData(response.data);

        if (response.data.length > 0) {
          const dynamicHeaders = Object.keys(response.data[0]).map((key) => ({
            label: formatHeaderLabel(key),
            value: key,
          }));
          setHeaders(dynamicHeaders);

          // By default, no headers are hidden when new headers are set
          setHiddenHeaders([]);
        } else {
          setHeaders([]);
          setHiddenHeaders([]);
        }
      } else {
        setData([]);
        setHeaders([]);
        setHiddenHeaders([]);
        setError('Invalid data format received from server.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to retrieve data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Helper function to format header labels
  const formatHeaderLabel = (key) => {
    // Map key to label if needed
    return key.replace(/([A-Z])/g, " $1").trim();
  };

  // Function to handle sorting
  const handleSort = (column) => {
    const isAsc = orderBy === column && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(column);

    const sortedData = [...data].sort((a, b) => {
      const aValue = a[column];
      const bValue = b[column];

      // Handle undefined or null values
      if (aValue === undefined || aValue === null) return 1;
      if (bValue === undefined || bValue === null) return -1;

      // Numeric sorting
      if (typeof aValue === "number" && typeof bValue === "number") {
        return isAsc ? aValue - bValue : bValue - aValue;
      }

      // Date sorting
      if (isValidDate(aValue) && isValidDate(bValue)) {
        return isAsc
          ? new Date(aValue) - new Date(bValue)
          : new Date(bValue) - new Date(aValue);
      }

      // String sorting
      return isAsc
        ? aValue.toString().localeCompare(bValue.toString())
        : bValue.toString().localeCompare(aValue.toString());
    });

    setData(sortedData);
  };

  // Helper function to check if a string is a valid date
  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date);
  };

  // Function to handle action button click
  const handleAction = (row) => {
    const dateTimeStr =
      row.DateTime ||
      row.dateTime ||
      row.Date ||
      '';

    if (!dateTimeStr) {
      console.error('DateTime string is undefined in row:', row);
      alert('DateTime information is missing for this entry.');
      return;
    }

    const url = `${window.location.origin}/one-min-data?symbol=${encodeURIComponent(
      symbol
    )}&dateTime=${encodeURIComponent(dateTimeStr)}&fullData=${fullOneMinData}`;

    // Open the URL in a new tab
    window.open(url, '_blank');
  };

  // Handle header selection change
  const handleHeaderChange = (event) => {
    const value = event.target.value;

    if (value.includes("select_all")) {
      if (hiddenHeaders.length === headers.length) {
        setHiddenHeaders([]); // Unhide all if all are hidden
      } else {
        setHiddenHeaders(headers.map((header) => header.value)); // Hide all if not all are hidden
      }
    } else {
      setHiddenHeaders(value);
    }
  };

  return (
    <Box sx={{ p: 2 }}>

<Grid container spacing={4}>
  <Grid item xs={4}>
  
  </Grid>
  <Grid item xs={4}>
  <Typography variant="h5" sx={{ mb: 2 , mt:2}}>
        15-Minute Data for {symbol} on {date}
      </Typography>
  </Grid>
  <Grid item xs={4}>
  
  </Grid>
  <Grid item xs={7}>
  
  </Grid>
  <Grid item xs={2}>
      {/* Full 1 Min Data Checkbox */}
      <FormControlLabel
        control={
          <Checkbox
            checked={fullOneMinData}
            onChange={(e) => setFullOneMinData(e.target.checked)}
          />
        }
        label="Full 1 Min Data"
      />
  </Grid>
  <Grid item xs={3}>
  {data.length > 0 && (
        <Box sx={{  mb: 2 , width: "260px"}}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="header-select-label">Hide Columns</InputLabel>
            <Select
              labelId="header-select-label"
              id="header-select"
              multiple
              value={hiddenHeaders}
              onChange={handleHeaderChange}
              label="Hide Columns"
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>None</em>;
                }

                if (selected.length === headers.length) {
                  return "All Columns Hidden";
                }

                return `${selected.length} columns hidden`;
              }}
            >
              {/* Select All Option */}
              <MenuItem key="select_all" value="select_all">
                <Checkbox
                  checked={
                    hiddenHeaders.length === headers.length && headers.length > 0
                  }
                  indeterminate={
                    hiddenHeaders.length > 0 &&
                    hiddenHeaders.length < headers.length
                  }
                />
                <ListItemText primary="Select All" />
              </MenuItem>

              {/* Individual Options */}
              {headers.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  <Checkbox checked={hiddenHeaders.includes(item.value)} />
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

  </Grid>
</Grid>

      
    
      {/* Header Selector */}
    
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 4,
          }}
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography variant="body1" color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      ) : data.length > 0 ? (
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: "100vh",
            width: "100%",
            overflowY: "auto",
            scrollBehavior: "smooth",
            boxShadow: 3,
          }}
        >
          <Table stickyHeader aria-label="15-min data table">
            <TableHead>
              <TableRow>
                {headers
                  .filter((header) => !hiddenHeaders.includes(header.value))
                  .map((header) => (
                    <StyledTableCell
                      key={header.value}
                      onClick={() => header.value && handleSort(header.value)}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {header.label}
                        {orderBy === header.value ? (
                          order === "asc" ? (
                            <ArrowUpward fontSize="small" />
                          ) : (
                            <ArrowDownward fontSize="small" />
                          )
                        ) : null}
                      </Box>
                    </StyledTableCell>
                  ))}

                {/* Extra header for Actions */}
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow
                  key={index}
                  hover
                  sx={{
                    backgroundColor:
                      index % 2 === 0
                        ? theme.palette.action.hover
                        : theme.palette.background.default,
                  }}
                >
                  {headers
                    .filter((header) => !hiddenHeaders.includes(header.value))
                    .map((header) => (
                      <TableCell
                        key={header.value}
                        sx={{
                          textAlign: "center",
                          padding: "8px",
                          borderLeft: `1px solid ${theme.palette.divider}`,
                        }}
                      >
                        {row[header.value]}
                      </TableCell>
                    ))}

                  {/* Action Button */}
                  <TableCell
                    sx={{
                      textAlign: "center",
                      padding: "8px",
                      borderLeft: `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    <ActionButton
                      variant="contained"
                      onClick={() => handleAction(row)}
                    >
                      View1M
                    </ActionButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1" sx={{ textAlign: "center", mt: 4 }}>
          No data available for the selected date.
        </Typography>
      )}
    </Box>
  );
};

export default FifteenMinData;

// Styled components for better readability and maintainability
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  textAlign: "center",
  borderLeft: `1px solid ${theme.palette.divider}`,
  cursor: "pointer",
  userSelect: "none",
  position: "sticky",
  top: 0,
  zIndex: 1,
}));

const ActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  color: theme.palette.common.white,
  padding: "6px 16px",
  "&:hover": {
    backgroundColor: theme.palette.success.dark,
    color: theme.palette.common.white,
  },
  textTransform: "none",
}));
