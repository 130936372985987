// src/pages/OptionsChain.js

import React, { useState, useCallback, useEffect, useMemo, useReducer } from 'react';
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Button,
  Snackbar,
  Alert,
  Tooltip,
} from '@mui/material';
import axios from 'axios';
import HomePage from './HomePage';

/**
 * Helper function to format symbols consistently.
 */
const formatSymbol = (symbol) => {
  return symbol.startsWith("NSE:")
    ? symbol.trim().toUpperCase()
    : `NSE:${symbol.trim().toUpperCase()}`;
};

/**
 * Helper function to format numbers.
 */
const formatNumber = (number) => {
  if (number === undefined || number === null) return "-";
  return new Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(number);
};

/**
 * Table Row Component
 * Renders a single row for Calls and Puts.
 */
const OptionRow = ({ strikePrice, callData, putData, index }) => {
  return (
    <TableRow
      sx={{
        backgroundColor: index % 2 === 0 ? 'grey.100' : 'white',
        '&:hover': {
          backgroundColor: 'action.hover',
        },
      }}
    >
      {/* Call Data */}
      <TableCell sx={{ fontSize: '0.875rem', fontWeight: '400' }}>
        <Tooltip title={callData?.Symbol || ""}>
          <span>{callData?.Symbol.replace("NSE:", "") || "-"}</span>
        </Tooltip>
      </TableCell>
      <TableCell align="right" sx={{ fontSize: '0.875rem', fontWeight: '400' }}>
        {formatNumber(callData?.Oi)}
      </TableCell>
      <TableCell align="right" sx={{ fontSize: '0.875rem', fontWeight: '400' }}>
        {formatNumber(callData?.Volume)}
      </TableCell>
      <TableCell align="right" sx={{ fontSize: '0.875rem', fontWeight: '400' }}>
        {formatNumber(callData?.Ltpchp)}
      </TableCell>
      <TableCell align="right" sx={{ fontSize: '0.875rem', fontWeight: '400' }}>
        {formatNumber(callData?.Ltpch)}
      </TableCell>
      <TableCell align="right" sx={{ fontSize: '0.875rem', fontWeight: '400' }}>
        {formatNumber(callData?.Ltp)}
      </TableCell>

      {/* Strike Price */}
      <TableCell align="center" sx={{ fontWeight: '500', fontSize: '0.875rem', backgroundColor: 'primary.light' }}>
        {strikePrice}
      </TableCell>

      {/* Put Data */}
      <TableCell align="right" sx={{ fontSize: '0.875rem', fontWeight: '400' }}>
        {formatNumber(putData?.Ltp)}
      </TableCell>
      <TableCell align="right" sx={{ fontSize: '0.865rem', fontWeight: '400' }}>
        {formatNumber(putData?.Ltpch)}
      </TableCell>
      <TableCell align="right" sx={{ fontSize: '0.875rem', fontWeight: '400' }}>
        {formatNumber(putData?.Ltpchp)}
      </TableCell>
      <TableCell align="right" sx={{ fontSize: '0.875rem', fontWeight: '400' }}>
        {formatNumber(putData?.Volume)}
      </TableCell>
      <TableCell align="right" sx={{ fontSize: '0.875rem', fontWeight: '400' }}>
        {formatNumber(putData?.Oi)}
      </TableCell>
      <TableCell sx={{ fontSize: '0.875rem', fontWeight: '400' }}>
        <Tooltip title={putData?.Symbol || ""}>
          <span>{putData?.Symbol.replace("NSE:", "") || "-"}</span>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

/**
 * Reducer for managing optionsData as an Object for efficient updates.
 */
const optionsReducer = (state, action) => {
  switch (action.type) {
    case "SET_OPTIONS":
      // Initialize the state with optionsData
      const newState = {};
      action.payload.forEach((item) => {
        const key = `${formatSymbol(item.Symbol)}-${item.Option_type}`;
        newState[key] = {
          ...item,
          Ltp: item.Ltp,
          Ltpch: item.Ltpch,
          Ltpchp: item.Ltpchp,
          Oi: item.Oi,
        };
      });
      return newState;

    case "UPDATE_LTP":
      // Update multiple LTPs at once
      const updatedState = { ...state };
      action.payload.forEach(({ symbol, ltp, ltpch, ltpchp, oi }) => {
        ["CE", "PE"].forEach((optionType) => {
          const key = `${formatSymbol(symbol)}-${optionType}`;
          if (updatedState[key]) {
            updatedState[key] = { ...updatedState[key], Ltp: ltp, Ltpch: ltpch, Ltpchp: ltpchp, Oi: oi };
          }
        });
      });
      return updatedState;

    default:
      return state;
  }
};

const OptionsChain = () => {
  // State Declarations
  const [symbol, setSymbol] = useState('');
  const [strikeCount, setStrikeCount] = useState(10);
  const [expiryDates, setExpiryDates] = useState([]);
  const [selectedExpiry, setSelectedExpiry] = useState('');
  const [vixData, setVixData] = useState(null);
  const [optionsData, dispatchOptionsData] = useReducer(optionsReducer, {});
  const [indexData, setIndexData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isExpiryLoading, setIsExpiryLoading] = useState(false);
  const [error, setError] = useState('');

  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Fetch symbol suggestions with debouncing
  const [symbolOptions, setSymbolOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const fetchSuggestions = useCallback(async (query) => {
    if (query.length === 0) {
      setSymbolOptions([]);
      return;
    }

    try {
      const response = await fetch(
        `https://api.arasu.online/api/Symbol/UnderSymSuggestions?query=${encodeURIComponent(
          query
        )}&limit=25`
      );
      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }
      const data = await response.json();
      setSymbolOptions(data);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setError("Failed to fetch symbol suggestions.");
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchSuggestions(inputValue);
    }, 300); // Debounce the API call by 300ms

    return () => clearTimeout(delayDebounceFn);
  }, [inputValue, fetchSuggestions]);

  // Function to fetch expiry dates
  const handleFetchExpiryDates = async () => {
    if (!symbol || !strikeCount) {
      setError('Please enter both symbol and strike count.');
      return;
    }

    setIsExpiryLoading(true);
    setError('');
    setExpiryDates([]);
    setSelectedExpiry('');
    setVixData(null);
    dispatchOptionsData({ type: "SET_OPTIONS", payload: [] }); // Clear optionsData
    setIndexData(null);

    try {
      const response = await axios.get(
        'https://api.arasu.online/api/Data/GetOptionsChain',
        {
          params: {
            symbol: formatSymbol(symbol),
            strikeCount: strikeCount,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (response.data.status === 1) {
        // Process expiry dates
        const expiryData = response.data.data.data.expiryData.map((item) => ({
          date: item.Date,
          expiry: item.Expiry,
        }));
        setExpiryDates(expiryData);
        setIndexData(response.data.data.data.optionsChainData[0].Ltp);

        // Notify user of successful fetch
        setNotification({
          open: true,
          message: "Expiry dates fetched successfully.",
          severity: "success",
        });
      } else {
        setError('Failed to fetch expiry dates.');
      }
    } catch (err) {
      console.error(err);
      setError('An error occurred while fetching expiry dates.');
    } finally {
      setIsExpiryLoading(false);
    }
  };

  // Function to fetch options chain data for the selected expiry
  const handleFetchOptionsChain = async (expiry = selectedExpiry) => {
    if (!expiry) {
      setError('Please select an expiry date.');
      return;
    }

    setIsLoading(true);
    setError('');
    setVixData(null);
    dispatchOptionsData({ type: "SET_OPTIONS", payload: [] }); // Clear existing options
    setIndexData(null);

    try {
      const response = await axios.get(
        'https://api.arasu.online/api/Data/GetOptionsChain',
        {
          params: {
            symbol: formatSymbol(symbol),
            strikeCount: strikeCount,
            expiry: expiry,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (response.data.status === 1) {
        setVixData(response.data.data.data.indiavixData);
        setIndexData(response.data.data.data.optionsChainData[0]);

        const optionsChainData = response.data.data.data.optionsChainData.slice(1); // Exclude indexData

        // Dispatch to set optionsData
        dispatchOptionsData({ type: "SET_OPTIONS", payload: optionsChainData });

        // Notify user of successful fetch
        setNotification({
          open: true,
          message: "Options chain data fetched successfully.",
          severity: "success",
        });

      } else {
        setError('Failed to fetch options chain data.');
      }
    } catch (err) {
      console.error(err);
      setError('An error occurred while fetching options chain data.');
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle expiry change
  const handleExpiryChange = (event) => {
    const newExpiry = event.target.value;
    setSelectedExpiry(newExpiry);
    handleFetchOptionsChain(newExpiry);
  };

  // Process options data for table display (memoized)
  const processedOptions = useMemo(() => {
    const processedData = {};

    Object.values(optionsData).forEach((item) => {
      const strikePrice = item.Strike_price;
      const optionType = item.Option_type;

      if (!processedData[strikePrice]) {
        processedData[strikePrice] = {};
      }

      processedData[strikePrice][optionType] = item;
    });

    return processedData;
  }, [optionsData]);

  // Handle Snackbar close
  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification((prev) => ({ ...prev, open: false }));
  };

  return (
    <>
      <HomePage />
      <Box sx={{ p: 2, m: 2, backgroundColor: '#f5f5f5', borderRadius: 2 }}>
        <Typography
          variant="h5"
          sx={{
            mb: 4,
            mt: 2,
            textAlign: "center",
            fontWeight: 700,
            color: "primary.main",
          }}
        >
          Options Chain
        </Typography>

        <Box sx={{ width: "100%" }}>
          {/* Display Error Messages */}
          {error && (
            <Box sx={{ mb: 2 }}>
              <Alert severity="error" onClose={() => setError("")}>
                {error}
              </Alert>
            </Box>
          )}

          {/* Notification Snackbar */}
          <Snackbar
            open={notification.open}
            autoHideDuration={6000}
            onClose={handleCloseNotification}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseNotification}
              severity={notification.severity}
              sx={{ width: "100%" }}
            >
              {notification.message}
            </Alert>
          </Snackbar>

          {/* Input Controls */}
          <Grid
            container
            spacing={2}
            alignItems="center"
            wrap="wrap"
            sx={{
              overflowX: "auto",
            }}
          >
            {/* Symbol Input */}
            <Grid item xs={12} sm={6} md={3} minWidth={200}>
              <Autocomplete
                freeSolo
                fullWidth
                options={symbolOptions}
                getOptionLabel={(option) => option.underSym || ""}
                value={symbolOptions.find(opt => opt.underSym === symbol) || null}
                onChange={(event, newValue) => {
                  setSymbol(newValue ? newValue.underSym : '');
                  setError('');
                }}
                onInputChange={(event, newInputValue) => {
                  setSymbol(newInputValue);
                  setError('');
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Symbol"
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            {/* Strike Count Input */}
            <Grid item xs={12} sm={6} md={3} minWidth={150}>
              <TextField
                label="Strike Count"
                type="number"
                value={strikeCount}
                onChange={(e) => setStrikeCount(Number(e.target.value))}
                fullWidth
                InputProps={{ inputProps: { min: 1 } }}
              />
            </Grid>

            {/* Get Expiry Dates Button */}
            <Grid item xs={12} sm={6} md={3} minWidth={150}>
              <Button
                variant="contained"
                onClick={handleFetchExpiryDates}
                fullWidth
                disabled={isExpiryLoading || !symbol}
              >
                {isExpiryLoading ? <CircularProgress size={24} /> : "Get Expiry Dates"}
              </Button>
            </Grid>
          </Grid>

          {/* Expiry Date Selector */}
          {expiryDates.length > 0 && (
            <Box sx={{ mb: 2, mt: 2 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6} md={3} minWidth={200}>
                  <FormControl fullWidth>
                    <InputLabel id="expiry-select-label">Expiry Date</InputLabel>
                    <Select
                      labelId="expiry-select-label"
                      value={selectedExpiry}
                      label="Expiry Date"
                      onChange={handleExpiryChange}
                    >
                      {expiryDates.map((expiry) => (
                        <MenuItem key={expiry.expiry} value={expiry.expiry}>
                          {expiry.date}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3} minWidth={150}>
                  <Button
                    variant="contained"
                    onClick={() => handleFetchOptionsChain(selectedExpiry)}
                    fullWidth
                    disabled={isLoading || !selectedExpiry}
                  >
                    {isLoading ? <CircularProgress size={24} /> : "Get Options Chain"}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}

          {/* Loading Indicator */}
          {(isLoading || isExpiryLoading) && (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
              <CircularProgress />
            </Box>
          )}

          {/* VIX and Index Data */}
          {vixData && (
            <TableContainer component={Paper} sx={{ mb: 2, mt: 4, maxHeight: 300, overflowX: 'auto' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        backgroundColor: 'primary.main',
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: '1rem',
                      }}
                    >
                      Index Name
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        backgroundColor: 'primary.main',
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: '1rem',
                      }}
                    >
                      LTP
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        backgroundColor: 'primary.main',
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: '1rem',
                      }}
                    >
                      Change
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        backgroundColor: 'primary.main',
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: '1rem',
                      }}
                    >
                      Change %
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vixData.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor: index % 2 === 0 ? 'grey.100' : 'white',
                        '&:hover': {
                          backgroundColor: 'action.hover',
                        },
                      }}
                    >
                      <TableCell>{item.Description}</TableCell>
                      <TableCell align="right">{formatNumber(item.Ltp)}</TableCell>
                      <TableCell align="right">{formatNumber(item.Ltpch)}</TableCell>
                      <TableCell align="right">{formatNumber(item.Ltpchp)}</TableCell>
                    </TableRow>
                  ))}

                  {/* Display Index Data if Available */}
                  {indexData && (
                    <TableRow >
                      <TableCell>{indexData.Description}</TableCell>
                      <TableCell align="right">{formatNumber(indexData.Ltp)}</TableCell>
                      <TableCell align="right">{formatNumber(indexData.Ltpch)}</TableCell>
                      <TableCell align="right">{formatNumber(indexData.Ltpchp)}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {/* Options Chain Data */}
          {Object.keys(processedOptions).length > 0 && (
            <TableContainer component={Paper} sx={{ maxHeight: 500, overflowX: 'auto' }}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={6}
                      sx={{
                        fontWeight: 'bold',
                        color: '#fff',
                        backgroundColor: 'primary.dark',
                        textAlign: 'center',
                      }}
                    >
                      Calls
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: 'bold',
                        color: '#fff',
                        backgroundColor: 'secondary.main',
                        textAlign: 'center',
                      }}
                    >
                      Strike Price
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={6}
                      sx={{
                        fontWeight: 'bold',
                        color: '#fff',
                        backgroundColor: 'primary.dark',
                        textAlign: 'center',
                      }}
                    >
                      Puts
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {/* Call Headers */}
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        color: 'white',
                        backgroundColor: 'primary.main',
                      }}
                    >
                      Symbol
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        fontWeight: 'bold',
                        color: 'white',
                        backgroundColor: 'primary.main',
                      }}
                    >
                      OI
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        fontWeight: 'bold',
                        color: 'white',
                        backgroundColor: 'primary.main',
                      }}
                    >
                      Volume
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        fontWeight: 'bold',
                        color: 'white',
                        backgroundColor: 'primary.main',
                      }}
                    >
                      Change%
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        fontWeight: 'bold',
                        color: 'white',
                        backgroundColor: 'primary.main',
                      }}
                    >
                      Change
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        fontWeight: 'bold',
                        color: 'white',
                        backgroundColor: 'primary.main',
                      }}
                    >
                      LTP
                    </TableCell>

                    {/* Strike Price */}
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: 'bold',
                        color: 'white',
                        backgroundColor: 'secondary.main',
                      }}
                    >
                      Strike Price
                    </TableCell>

                    {/* Put Headers */}
                    <TableCell
                      align="right"
                      sx={{
                        fontWeight: 'bold',
                        color: 'white',
                        backgroundColor: 'primary.main',
                      }}
                    >
                      LTP
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        fontWeight: 'bold',
                        color: 'white',
                        backgroundColor: 'primary.main',
                      }}
                    >
                      Change
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        fontWeight: 'bold',
                        color: 'white',
                        backgroundColor: 'primary.main',
                      }}
                    >
                      Change%
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        fontWeight: 'bold',
                        color: 'white',
                        backgroundColor: 'primary.main',
                      }}
                    >
                      Volume
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        fontWeight: 'bold',
                        color: 'white',
                        backgroundColor: 'primary.main',
                      }}
                    >
                      OI
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        color: 'white',
                        backgroundColor: 'primary.main',
                      }}
                    >
                      Symbol
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(processedOptions).map(
                    ([strikePrice, data], index) => (
                      <OptionRow
                        key={strikePrice}
                        strikePrice={strikePrice}
                        callData={data["CE"]}
                        putData={data["PE"]}
                        index={index}
                      />
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Box>

      {/* Notification Snackbar at Bottom Center */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification.severity}
          sx={{ width: "100%" }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default OptionsChain;
