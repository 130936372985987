import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import UpdateIcon from "@mui/icons-material/Update";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import HomePage from "./HomePage";
import useMediaQuery from "@mui/material/useMediaQuery";

// Import your new components

import GeneralSettings from "./generalSettings";
import BulkSymbolAdd from "./BulkSymbolAdd";
import SubscribeSymbol from "./SubscribeSymbols";
import StockDatas from "./StockDatas";

// import RefreshToken from "./RefreshToken"; // Optional

const drawerWidth = 240;

// Styled Drawer component
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiDrawer-paper": {
    marginTop: "65px",
    width: drawerWidth,
    backgroundColor: theme.palette.background.default,
  },
}));

export default function Settings() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // State to track selected menu item
  const [selectedMenu, setSelectedMenu] = React.useState("Home");
 

  // Menu configuration array
  const menuItems = [
    {
      text: "Mac Datas",
      icon: <DashboardIcon />,
      component: <StockDatas />,
    },
    {
      text: "Bulk Symbol Add",
      icon: <AddBoxIcon />,
      component: <BulkSymbolAdd />,
    },
    {
      text: "Subscribe Symbol",
      icon: <SubscriptionsIcon />,
      component: <SubscribeSymbol />,
    },
    {
      text: "General Settings",
      icon: <UpdateIcon />,
      component: <GeneralSettings />,
    }
    
  ];

  // Function to render the selected component
  const renderContent = () => {
    const menu = menuItems.find((item) => item.text === selectedMenu);
    return menu ? menu.component : <HomePage />;
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <Drawer variant={isMobile ? "temporary" : "permanent"}>
        <List>
          {/* Menu Items */}
          {menuItems.map((item) => (
            <ListItem key={item.text} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                onClick={() => setSelectedMenu(item.text)}
                sx={{
                  minHeight: 48,
                  px: 2.5,
                  backgroundColor:
                    selectedMenu === item.text
                      ? "rgba(0, 0, 0, 0.08)"
                      : "inherit",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 2,
                    justifyContent: "center",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1 }}>
        {renderContent()}
      </Box>
    </Box>
  );
}
