// src/pages/OneMinData.js

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Grid,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import axios from "axios";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";

const OneMinData = () => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [hiddenHeaders, setHiddenHeaders] = useState([]);

  const location = useLocation();

  // Function to parse query parameters
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };

  const query = useQuery();
  const symbol = query.get("symbol");
  const dateTime = query.get("dateTime");
  const fullData = query.get("fullData") === "true";

  useEffect(() => {
    if (symbol && dateTime) {
      fetchData();
    } else {
      setError("Symbol or dateTime parameter is missing.");
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [symbol, dateTime, fullData]);

  const fetchData = async () => {
    setIsLoading(true);
    setError("");
    try {
      const token = localStorage.getItem("token");
      const payload = {
        symbol: symbol,
        dateTime: dateTime,
        fullData: fullData,
      };
      const response = await axios.post(
        "https://api.arasu.online/api/Data/GetOneMinData",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (Array.isArray(response.data)) {
        setData(response.data);

        console.log(response.data);

        if (response.data.length > 0) {
          const dynamicHeaders = Object.keys(response.data[0]).map((key) => ({
            label: formatHeaderLabel(key),
            value: key,
            color: getHeaderColor(key), // Assign color here
          }));
          setHeaders(dynamicHeaders);

          // By default, no headers are hidden when new headers are set
          setHiddenHeaders([]);
        } else {
          setHeaders([]);
          setHiddenHeaders([]);
        }
      } else {
        setData([]);
        setHeaders([]);
        setHiddenHeaders([]);
        setError("Invalid data format received from server.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to retrieve data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Helper function to format header labels
  const formatHeaderLabel = (key) => {
    // Map key to label if needed
    return key.replace(/([A-Z])/g, " $1").trim();
  };

  // Assign specific colors based on key
  const getHeaderColor = (key) => {
    switch (key) {
      // Green for price
      case "O_C_D-TH":
        return theme.palette.success.main; // Orange for volume
      case "H_C_D-TH":
        return theme.palette.success.main;
      case "L_C_D-TH":
        return theme.palette.success.main;
      case "C_C_D-TH":
        return theme.palette.success.main;
      case "O_C_D-PD":
        return theme.palette.warning.main;
      case "H_C_D-PD":
        return theme.palette.warning.main;
      case "L_C_D-PD":
        return theme.palette.warning.main;
      case "C_C_D-PD":
        return theme.palette.warning.main;
      case "O_C_D-CD":
        return "#9847f5";
      case "H_C_D-CD":
        return "#9847f5";
      case "L_C_D-CD":
        return "#9847f5";
      case "C_C_D-CD":
        return "#9847f5";
      case "O_C_L15":
        return "#72cc18";
      case "H_C_L15":
        return "#72cc18";
      case "L_C_L15":
        return "#72cc18";
      case "C_C_L15":
        return "#72cc18";

      default:
        return theme.palette.primary.main; // Default primary color
    }
  };

  // Function to handle sorting
  const handleSort = (column) => {
    const isAsc = orderBy === column && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(column);

    const sortedData = [...data].sort((a, b) => {
      const aValue = a[column];
      const bValue = b[column];

      // Handle undefined or null values
      if (aValue === undefined || aValue === null) return 1;
      if (bValue === undefined || bValue === null) return -1;

      // Numeric sorting
      if (typeof aValue === "number" && typeof bValue === "number") {
        return isAsc ? aValue - bValue : bValue - aValue;
      }

      // Date sorting
      if (isValidDate(aValue) && isValidDate(bValue)) {
        return isAsc
          ? new Date(aValue) - new Date(bValue)
          : new Date(bValue) - new Date(aValue);
      }

      // String sorting
      return isAsc
        ? aValue.toString().localeCompare(bValue.toString())
        : bValue.toString().localeCompare(aValue.toString());
    });

    setData(sortedData);
  };

  // Helper function to check if a string is a valid date
  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date);
  };

  // Handle header selection change
  const handleHeaderChange = (event) => {
    const value = event.target.value;

    if (value.includes("select_all")) {
      if (hiddenHeaders.length === headers.length) {
        setHiddenHeaders([]); // Unhide all if all are hidden
      } else {
        setHiddenHeaders(headers.map((header) => header.value)); // Hide all if not all are hidden
      }
    } else {
      setHiddenHeaders(value);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={4}>
        <Grid item xs={4}></Grid>

        <Grid item xs={4}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            1 Min Data for {symbol} on {dateTime}
          </Typography>
        </Grid>

        <Grid item xs={4}></Grid>

        <Grid item xs={3}></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}>
          {/* Header Selector */}
          {data.length > 0 && (
            <Box sx={{ mb: 2, width: "260px" }}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="header-select-label">Hide Columns</InputLabel>
                <Select
                  labelId="header-select-label"
                  id="header-select"
                  multiple
                  value={hiddenHeaders}
                  onChange={handleHeaderChange}
                  label="Hide Columns"
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>None</em>;
                    }

                    if (selected.length === headers.length) {
                      return "All Columns Hidden";
                    }

                    return `${selected.length} columns hidden`;
                  }}
                >
                  {/* Select All Option */}
                  <MenuItem key="select_all" value="select_all">
                    <Checkbox
                      checked={
                        hiddenHeaders.length === headers.length &&
                        headers.length > 0
                      }
                      indeterminate={
                        hiddenHeaders.length > 0 &&
                        hiddenHeaders.length < headers.length
                      }
                    />
                    <ListItemText primary="Select All" />
                  </MenuItem>

                  {/* Individual Options */}
                  {headers.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      <Checkbox checked={hiddenHeaders.includes(item.value)} />
                      <ListItemText primary={item.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </Grid>
      </Grid>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 4,
          }}
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography variant="body1" color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      ) : data.length > 0 ? (
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: "100vh",
            width: "100%",
            overflowY: "auto",
            scrollBehavior: "smooth",
            boxShadow: 3,
          }}
        >
          <Table stickyHeader aria-label="1-min data table">
            <TableHead>
              <TableRow>
                {headers
                  .filter((header) => !hiddenHeaders.includes(header.value))
                  .map((header) => (
                    <StyledTableCell
                      key={header.value}
                      bgcolor={header.color} // Apply specific color
                      bordercolor={header.color}
                      onClick={() => header.value && handleSort(header.value)}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {header.label}
                        {orderBy === header.value ? (
                          order === "asc" ? (
                            <ArrowUpward fontSize="small" />
                          ) : (
                            <ArrowDownward fontSize="small" />
                          )
                        ) : null}
                      </Box>
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow
                  key={index}
                  hover
                  sx={{
                    backgroundColor:
                      index % 2 === 0
                        ? theme.palette.action.hover
                        : theme.palette.background.default,
                  }}
                >
                  {headers
                    .filter((header) => !hiddenHeaders.includes(header.value))
                    .map((header) => (
                      <TableCell
                        key={header.value}
                        sx={{
                          textAlign: "center",
                          padding: "7px",
                          margin:"1px",
                          borderLeft: `1px solid ${header.color ||theme.palette.divider}`,
                         
                        }}
                      >
                        {row[header.value]}
                      </TableCell>
                    ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1" sx={{ textAlign: "center", mt: 4 }}>
          No data available for the selected time.
        </Typography>
      )}
    </Box>
  );
};

export default OneMinData;

// Styled components
const StyledTableCell = styled(TableCell)(({ theme, bgcolor, bordercolor  }) => ({
  fontWeight: "bold",
  color: theme.palette.common.white,
  backgroundColor: bgcolor || theme.palette.primary.main,
  textAlign: "center",
  borderRight: `1px solid ${bordercolor ||theme.palette.divider}`,
  cursor: "pointer",
  userSelect: "none",
  position: "sticky",
  top: 0,
  zIndex: 1,
}));
